import dynamic from 'next/dynamic'

const ContentImageBlock = dynamic(() => import('@/components/content/content-image-block'))
const ContentVideoBlock = dynamic(() => import('@/components/content/content-video-block'))
const ContentTextBlock = dynamic(() => import('@/components/content/content-text-block'))

const Content7030 = (props) => {
  const layout = props?.layout || 'Text on left'
  const contentLeft = layout == 'Text on left' ? props?.textBlock : props?.mediaBlock
  const contentRight = layout == 'Text on left' ? props?.mediaBlock : props?.textBlock

  return (
    <>
      {contentLeft && contentRight && (
        <div
          className={`content-7030  ${
            layout == 'Text on left' ? 'content-7030--70' : 'content-7030--30'
          }`}
        >
          <div className="grid lg:grid-cols-2">
            <div
              className={`content-7030__col-left flex h-full ${
                layout == 'Text on left' ? 'order-2 lg:order-1' : 'order-1'
              }`}
            >
              {layout == 'Text on left' ? (
                <ContentTextBlock {...contentLeft} />
              ) : (
                <>
                  {contentLeft?.__typename == 'VideoBlock' ? (
                    <ContentVideoBlock
                      {...contentLeft}
                      popupPosition={layout == 'Text on left' ? 'right' : 'left'}
                    />
                  ) : (
                    <ContentImageBlock
                      {...contentLeft}
                      popupPosition={layout == 'Text on left' ? 'right' : 'left'}
                    />
                  )}
                </>
              )}
            </div>
            <div
              className={`content-7030__col-right flex  h-full ${
                layout == 'Text on left' ? 'order-1 lg:order-2' : 'order-2'
              }`}
            >
              {layout == 'Text on right' ? (
                <ContentTextBlock {...contentRight} />
              ) : (
                <>
                  {contentRight?.__typename == 'VideoBlock' ? (
                    <ContentVideoBlock
                      {...contentRight}
                      popupPosition={layout == 'Text on left' ? 'right' : 'left'}
                    />
                  ) : (
                    <ContentImageBlock
                      {...contentRight}
                      popupPosition={layout == 'Text on left' ? 'right' : 'left'}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Content7030
